import React, { useState } from "react";
import { FaShare } from "react-icons/fa";
import { BsMailbox, BsPhone, BsPhoneVibrate } from "react-icons/bs";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";

function UserCard({ data }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    const handleError = () => {
        toast.error("Bu bölüm geliştirme aşamasındadır!");
    };
    const handleEMail = () => {
        const endOfName = data.value.indexOf(" ");
        const firstOfName = data.value[0];
        const surname = data.value.slice(endOfName + 1, data.value.length);
        let email = "";
        if (data.value.split(" ").length > 2) {
            email = `N/A`;
        } else {
            email = `${firstOfName}.${surname}@marmarereglisi.bel.tr`.toLowerCase();
        }
        return email;
    };
    return (
        <div className={"bg-white flex flex-col shadow-lg w-full rounded-xl select-none"}>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <div className="modal flex p-1 rounded-xl h-48 flex-col  items-center  justify-center ">
                    <QRCode
                        value={
                            "BEGIN:VCARD\n" +
                            "VERSION:4.0\n" +
                            `FN:${data.value}\n` +
                            "ORG:Marmaraereglisi Belediyesi\n" +
                            `TEL;TYPE=work,voice;VALUE=uri:tel:+90 850 440 ${data.internal}\n` +
                            `EMAIL:${handleEMail()}\n` +
                            "END:VCARD"
                        }
                    />
                </div>
            </Popup>
            {/*Header*/}
            <div className={"flex justify-between items-center flex-row px-2 mb-1 mt-2"}>
                <h2 className={"uppercase text-gray-600 font-bold px-2"}>
                    {data.value}
                </h2>
                <FaShare onClick={handleError} />
            </div>
            {/*Content*/}
            <div className={"flex flex-row"}>
                {/*QR Code*/}
                <div title={t("qrCodeTitle")} className={"text-gray-600 flex items-center justify-center mx-4 hover:scale-110 transition ease-linear duration-500 "}>
                    <QRCode
                        size={100}
                        onClick={() => setOpen((o) => !o)}
                        value={
                            "BEGIN:VCARD\n" +
                            "VERSION:4.0\n" +
                            `FN:${data.value}\n` +
                            "ORG:Marmaraereglisi Belediyesi\n" +
                            `TEL;TYPE=work,voice;VALUE=uri:tel:+90 850 440 ${data.internal}\n` +
                            `TEL;TYPE=cell:(123) 555-5832\n` +
                            `EMAIL:${handleEMail()}\n` +
                            "END:VCARD"
                        }
                    />
                </div>
                {/*Details*/}
                <div className={"text-gray-600 flex flex-col"}>
                    {/*Internal Phone*/}
                    <div title={t("internal")} className={"flex flex-row items-center mb-2"}>
                        <div className={"ml-0"}>
                            <BsPhone />
                        </div>
                        <div className={"mx-2"}>
                            <h3 className={"text-xs"}>{t("internal")}</h3>
                            <p className={"font-bold text-lg sm:text-xl"}>{data.internal}</p>
                        </div>
                    </div>
                    {/*External Phone*/}
                    <div title={t("external")} className={"flex flex-row items-center mb-2"}>
                        <div className={"ml-0"}>
                            <BsPhoneVibrate />
                        </div>
                        <div className={"mx-2"}>
                            <h3 className={"text-xs"}>{t("external")}</h3>
                            <a className={"font-bold text-lg sm:text-xl"} title={"Telefon"} href={`tel:+90850440${data.internal}`}>{`0 850 440 ${data.internal}`}</a>
                        </div>
                    </div>
                    {/*Mail Address*/}
                    <div title={t("mail")} className={"flex flex-row items-center mb-2"}>
                        <div className={"ml-0"}>
                            <BsMailbox />
                        </div>
                        <div className={"mx-2"}>
                            <h3 className={"text-xs"}>{t("mail")}</h3>
                            <a className={"font-bold text-xs sm:text- break-words"} href={`mailto:${handleEMail()}`}>
                                {handleEMail()}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserCard;
