import React, { useEffect, useState } from "react";
import UserCard from "./UserCard";
import { users } from "../Datas/PhoneBook";
import { FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

function UserList(props) {
    const { t } = useTranslation();
    // Online state
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };
        // Listen to the online status
        window.addEventListener("online", handleStatusChange);
        // Listen to the offline status
        window.addEventListener("offline", handleStatusChange);
        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener("online", handleStatusChange);
            window.removeEventListener("offline", handleStatusChange);
            if (isOnline === true) {
                toast.error(t("offline"), {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.success(t("online"), {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
    }, [isOnline]);
    //Get Datas From Database
    const contacts = users;
    //Filtered Data
    const [filteredData, setFilteredData] = useState(contacts);
    //Handle Data
    const handleChange = (e) => {
        if (e.target.value.toUpperCase().length > 2) {
            setFilteredData(
                contacts.filter((o) => o.label.includes(e.target.value.toUpperCase()
                    .replaceAll('Ö', 'O')
                    .replaceAll('Ü', 'U')
                    .replaceAll('İ', 'I')
                    .replaceAll('Ş', 'S')
                    .replaceAll('Ç', 'C')
                    .replaceAll('Ğ', 'G')
                ))
            );
        } else if (e.target.value.toUpperCase().length === 1) {
            setFilteredData(contacts);

        }
    };


    return (
        <div className={"bg-gray-100"}>
            <div className={"mt-0 mb-12 p-1 flex flex-col items-center "}>
                {/*Search Bar*/}
                <div className={"sticky sm:w-8/12 w-full top-0  flex items-center justify-between "}>
                    <input
                        title={"arama"}
                        onChange={(e) => handleChange(e)}
                        className={" bg-white opacity-90 m-2 py-2 px-8 w-full rounded-lg border border-gray-200 hover:bg-gray-200 hover:opacity-100 text-gray-500"}
                        type="search"
                        name="search"
                        id="txtSearch"
                        placeholder={t("search")}
                    />
                    <FaSearch className={"absolute left-6 text-gray-500"} />
                </div>
                {/*Data*/}
                <div className={"flex sm:w-full lg:w-8/12 sm:justify-between w-full flex-wrap  items-center "}>
                    {filteredData.map((item, index) => (
                        <div key={index} className={"sm:w-6/12   w-full rounded-xl p-2 "} >
                            <UserCard key={index} data={item} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default UserList;
