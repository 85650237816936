import React from "react";
import { FaGithub, FaInstagram, FaLocationArrow, FaMailBulk, FaPhone, FaStackOverflow, FaTwitter } from "react-icons/fa";
import i18n from "../i18n";
import { findFlagUrlByIso3Code } from "country-flags-svg";
import { useTranslation } from 'react-i18next';

function Footer(props) {
    const style = {
        iconButton: "text-gray-400 mx-1 text-sm hover:scale-105 hover:text-gray-300 transitions duration-500 ease-in-out",
        text: "text-gray-500",
    };
    const flags = {
        tr: findFlagUrlByIso3Code("TUR"),
        en: findFlagUrlByIso3Code("GBR"),
        ru: findFlagUrlByIso3Code("RUS"),
        de: findFlagUrlByIso3Code("DEU")
    }
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };
    const { t } = useTranslation();

    return (
        <div className={"fixed bottom-0 h-12 sm:h-12 hover:h-72 hover:sm:h-48  duration-700 transition-all hover:ease-linear hover:duration-300 text-gray-400 text-xs w-full text-start flex flex-col select-none"}>
            {/*Sub Footer */}
            <div className={"w-full flex flex-row justify-center h-10"}>
                <div className="w-12 flex rounded-tl-full  bg-slate-600"></div>
                <div className="w-12 flex  bg-slate-600 h-4  flex-col items-center justify-center">
                    <div className="text-gray-300 border-gray-400 border-b h-1 w-full flex "></div>
                    <div className="text-gray-300 border-gray-400 border-b h-1 w-full flex "></div>
                    <div className="text-gray-300 border-gray-400 border-b h-1 w-full flex "></div>
                </div>
                <div className="w-12 flex rounded-tr-full bg-slate-600"></div>
            </div>
            <div className={' flex flex-row w-full  bg-slate-600'}>
                {/*Title*/}
                <div className={"w-2/3 flex items-center font-bold"}>
                    <span className={"px-2 tracking-wider"}>{t("title")} {t("user")}</span>
                </div>
                {/*Languages*/}
                <div className={"w-1/3 flex items-center justify-end"}>
                    <div className={"my-1"}>
                        <button title={"Turkish"} onClick={() => changeLanguage("tr")}>
                            <img className={"w-7 h-5 mx-1 object-cover hover:scale-110 duration-500 opacity-50 hover:opacity-95"} src={flags.tr} alt="turkish" />
                        </button>
                        <button title={"English"} onClick={() => changeLanguage("en")}>
                            <img className={"w-7 h-5 mx-1 object-cover hover:scale-110 duration-500 opacity-50 hover:opacity-95"} src={flags.en} alt="english" />
                        </button>
                        <button title={"German"} onClick={() => changeLanguage("de")}>
                            <img className={"w-7 h-5 mx-1 object-cover hover:scale-110 duration-500 opacity-50 hover:opacity-95"} src={flags.de} alt="german" />
                        </button>
                        <button title={"Russian"} onClick={() => changeLanguage("ru")}>
                            <img
                                className={"w-7 h-5 mx-1 object-cover hover:scale-110 duration-500 opacity-50 hover:opacity-95"} src={flags.ru} alt="russian" />
                        </button>
                    </div>
                </div>
            </div>
            <div className=" flex flex-wrap sm:h-full sm:flex-row  sm:flex-nowrap  bg-slate-600">
                {/*About*/}
                <div className={"flex flex-col w-full sm:w-4/12 m-1"}>
                    <h3 className={"text-gray-300 font-bold p-1 uppercase"}>{t("headers.about")}</h3>
                    <div className="flex flex-col w-full text-start p-1">
                        <h3 className={""}>{t("user")}, {t("title")}</h3>
                        <h3 className={""}>{t("about")}
                        </h3>
                    </div>

                </div>
                {/*Works*/}
                <div className={"flex flex-col w-4/12 sm:w-3/12 m-1"}>
                    <h3 className={"text-gray-300 font-bold p-1 uppercase"}>{t("headers.works")}</h3>
                    <ul className="list-none list-inside justify-start text-start">
                        <li className="list-item p-1">
                            <a
                                className="hover:text-gray-300 duration-500 hover:duration-500"
                                title="6 February 2023 Turkey Earthquake Collapsed Building Map"
                                href="https://turkey-earthquake-damage-stats.web.app/"
                                target="_blank"
                                rel={"noreferrer"}
                                alt="turkey_earthquake_map" >{t("works.turkey_earthquake_map")}</a>

                        </li>
                        <li className="list-item p-1">
                            <a
                                className="hover:text-gray-300 duration-500 hover:duration-500"
                                title="Municipalty of Marmaraereglisi Corporated Phonebook"
                                href="https://rehber.erbilozum.com/"
                                target="_blank"
                                rel={"noreferrer"}
                                alt="corporated_phonebook" >{t("works.corporated_phonebook")}</a>
                        </li>
                        <li className="list-item p-1">
                            <a
                                className="hover:text-gray-300 duration-500 hover:duration-500"
                                title="Accident Control System"
                                href="https://none.erbilozum.com/"
                                target="_blank"
                                rel={"noreferrer"}
                                alt="accident_control_system" >{t("works.accident_control_system")}</a>
                        </li>
                        <li className="list-item p-1">
                            <a
                                className="hover:text-gray-300 duration-500 hover:duration-500"
                                title="Work Permit Document Template"
                                href="https://bekaert-tys.web.app//"
                                target="_blank"
                                rel={"noreferrer"}
                                alt="work_permit_document_template" >{t("works.work_permit_document_template")}</a>
                        </li>
                    </ul>
                </div>
                {/*Contact*/}
                <div className={"flex flex-col w-4/12 sm:w-3/12 m-1"}>
                    <h3 className={"text-gray-300 font-bold p-1 uppercase"}>{t("headers.contact")}</h3>
                    {/*Contact*/}
                    <div className="flex flex-row w-full items-center text-start p-1">
                        <FaLocationArrow size={12} />
                        <p className="px-2">{t("address")}</p>
                    </div>
                    {/*Phone*/}
                    <div className="flex flex-row w-full items-center text-start p-1">
                        <FaPhone size={12} />
                        <p className="px-2">{t("phone")}</p>
                    </div>
                    {/*E-Mail*/}
                    <div className="flex flex-row w-full items-center text-start p-1">
                        <FaMailBulk size={12} />
                        <p className="px-2">{t("mail")}</p>
                    </div>
                </div>
                {/*Social Media*/}
                <div className={"flex flex-col w-3/12 sm:w-2/12 m-1"}>
                    <h3 className={"text-gray-300 font-bold p-1 uppercase"}>{t("headers.social").toLocaleUpperCase()}</h3>
                    <ul className="list-none list-inside justify-start text-start">
                        {/*Github*/}
                        <li className="list-item p-1">
                            <div className="w-full flex flex-row items-center">
                                <a title={"Github"} href="https://www.github.com/erbilozum">
                                    <FaGithub className={style.iconButton} />
                                </a>
                                <p>Github</p>
                            </div>
                        </li>
                        {/*Stackoverflow*/}
                        <li className="list-item p-1">
                            <div className="w-full flex flex-row items-center">
                                <a title={"Stackoverflow"} href="https://www.stackoverflow.com/erbilozum">
                                    <FaStackOverflow className={style.iconButton} />
                                </a>
                                <p>Stackverflow</p>
                            </div>
                        </li>
                        {/*Instagram*/}
                        <li className="list-item p-1">
                            <div className="w-full flex flex-row items-center">
                                <a title={"Instagram"} href="https://www.instagram.com/erbilozum">
                                    <FaInstagram className={style.iconButton} />
                                </a>
                                <p>Instagram</p>
                            </div>
                        </li>
                        {/*Twitter*/}
                        <li className="list-item p-1">
                            <div className="w-full flex flex-row items-center">
                                <a title={"Twitter"} href="https://www.twitter.com/erbilozum">
                                    <FaTwitter className={style.iconButton} />
                                </a>
                                <p>Twitter</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div >
    );
}

export default Footer;
