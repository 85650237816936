import UserList from "./Components/UserList";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Components/Footer";
import { t } from "i18next";

function App() {
  document.title = t("pagetitle");
  return (
    <div className="bg-gray-100 h-screen">
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <UserList />
      <Footer />
    </div>
  );
}

export default App;
